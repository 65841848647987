import React from "react";
import { useSelector } from "react-redux";
import decoded from "jwt-decode";
import Index from "../login/Login";

const PrivateRoute = ({ children }) => {
  const user = useSelector((state) => state.persistedReducer.current);

  if (user) {
    var token = user && user.token;
    var decode = decoded(token);
    // console.log("logged in");
    // console.log(decode);
    return <div>{decode.role === "superadmin" || "admin" ? children : <Index />}</div>;
  } else if (user === null) {
    // console.log("not logged in");
    return <Index />;
  }
};

export default PrivateRoute;
