import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current: null,
};

const AdminReducer = createSlice({
  //   name: "pension",
  name: "admin",
  initialState,
  reducers: {
    user: (state, { payload }) => {
      state.current = payload;
    },
    signOut: (state, { payload }) => {
      state.current = null;
    },
  },
});

export const { user, signOut } = AdminReducer.actions;

export default AdminReducer.reducer;
