import styled from "styled-components";

export const LoginContainer = styled.div`
  height: 100vh;
  width: 100vw;
  // background-color: red;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media screen and (max-width: 1440px) {
    width: 100vw;
    height: auto;
    padding: 40px 0px;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: 100vh;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: 100vh;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: 100vh;
  }
`;
// const LoginContainer = styled.div``;
