import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Registration from "../registration/registrationForm/Registration";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import ClipLoader from "react-spinners/BeatLoader";
import parse from "html-react-parser";

import {
  DashboardWrapper,
  Header,
  Nav,
  NavWrapper,
  Body,
  AdminInfo,
  Tab,
  BlogTable,
  RegWrapper,
  ModalOverlay,
  ModalWrapper,
  ModalHeader,
  StartOfForm,
  FormContainer,
  NonForm,
  Form,
  EditBlogFormWrapper,
  PostEventFormWrapper,
  EditEventFormWrapper,
  FormWrapper,
  AdminHeader,
  AdminAccess,
  LoginButton,
  LoginLocked,
  Err,
  EndOfForm,
  UserForm,
} from "./indexStyle";
import decoded from "jwt-decode";
import adminAvatar from "../images/avatar.png";
import deleteIcon from "../images/deleteIcon.svg";
import editIcon from "../images/editIcon.svg";
import { signOut } from "../globals/Reducers/AdminReducer";
import axios from "axios";
import chamsLogo from "../images/ChamsLogo.svg";
import keyLock from "../images/shield-security.svg";

// import { user } from "../globals/Reducers/AdminReducer";

// userSignIn validation with yup and react-hook-form
const userSchema = yup.object().shape({
  username: yup.string().required("Enter a valid email address"),
  email: yup.string().email().required("Enter a valid email address"),
  password: yup.string().required("Enter a valid password"),
  role: yup.string().required("Select a valid role"),
});

// main component for dashboard
const Index = () => {
  const [blogData, setBlogData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = React.useState("all-blogs");
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [file1, setFile1] = useState();

  // blog state
  const [label, setLabel] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  // const [content, setContent] = useState("");
  const [captionImage, setCaptionImage] = useState(null);
  const [blogErrors, setBlogErrors] = useState([]);
  // end of blog states

  const [eventImages, setEventImages] = useState([]);
  // event states
  const [eventTitle, setEventTitle] = useState("");
  const [eventContent, setEventContent] = useState("");
  const [titleImage, setTitleImage] = useState(null);
  const [errors, setErrors] = useState([]);
  const [eventVideo, setEventVideo] = useState(null);
  // end of event states

  const user = useSelector((state) => state.persistedReducer.current);
  const dispatch = useDispatch();

  // start of global handlers
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleEditButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // handle changes for content on quill
  const handleQuillChange = (e) => {
    setContent(e);
  };
  // end of global handlers

  // handle any change on input field
  const handleChange1 = (e) => {
    e.preventDefault();
    // console.log(e.target.files[0]);
    setFile1(e.target.files[0]);
  };

  // start of handlers for blog
  const handleLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const handTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCaptionmageChange = (e) => {
    const image = e.target.files[0];
    setCaptionImage(image);
  };
  // end of event handlers for blogs

  // start of handlers for event
  const handleTitleChange = (e) => {
    // setEventTitle(e.target.value);
    setEventTitle(e.target.value);
  };

  const handleContentChange = (e) => {
    setEventContent(e.target.value);
  };

  const handleTitleImageChange = (e) => {
    const image = e.target.files[0];
    setTitleImage(image);
  };

  const handleEventVideoChange = (event) => {
    const videoFile = event.target.files[0];
    setEventVideo(videoFile);
  };

  const handleEventImagesChange = (e) => {
    const images = Array.from(e.target.files);
    setEventImages(images);
  };
  // end of event handlers for events

  // grab the token from the current user
  let decode;
  if (user) {
    var token = user && user.token;
    decode = decoded(token);
  }
  // console.log(decode);
  // console.log(decode.role);

  // SUPERADMIN gets all users (admin and user)
  // const allUserUrl = "http://localhost:5050/api/v1/users";
  const allUserUrl = "https://blogapi.chamsaccess.com/api/v1/users";
  // function to get all blogs from the database
  const fetchAllUsers = async () => {
    const response = await axios.get(allUserUrl, {
      headers: {
        Authorization: `Bearer ${user.token}`,
        "Content-Type": "application/json",
        Accept: "*",
        mode: "cors",
      },
    });
    // console.log(response.data.data);
    setUserData(response.data.data);
  };

  // GET - All blogs from the database
  // const allBlogUrl = "http://localhost:5050/api/v1/visitors/blogs";
  const allBlogUrl = "https://blogapi.chamsaccess.com/api/v1/visitors/blogs";
  const fetchAllBlogs = async () => {
    const response = await axios.get(allBlogUrl, {
      headers: {
        "Content-Type": "application/json",
        Accept: "*",
        mode: "cors",
      },
    });
    // console.log(response.data.data);
    setBlogData(response.data.data);
  };
  // GET - All event from the database
  // const allEventByUrl = "http://localhost:5050/api/v1/visitors/events";
  const allEventByUrl =
    "https://blogapi.chamsaccess.com/api/v1/visitors/events";
  const fetchAllEvents = async () => {
    const response = await axios.get(allEventByUrl, {
      headers: {
        "Content-Type": "application/json",
        Accept: "*",
        mode: "cors",
      },
    });
    // console.log(response.data.data);
    setEventData(response.data.data);
  };

  // GET - All blogs from the database by a specific user
  // const blogByRoleUrl = "http://localhost:5050/api/v1/admin/blogs";
  const blogByRoleUrl = "https://blogapi.chamsaccess.com/api/v1/admin/blogs";
  const fetchBlogsByRole = async () => {
    const response = await axios.get(blogByRoleUrl, {
      headers: {
        Authorization: `Bearer ${user.token}`,
        "Content-Type": "application/json",
        Accept: "*",
        mode: "cors",
      },
    });
    // console.log(response.data.data);
    setBlogData(response.data.data);
  };

  // GET - All events from the database by a specific user
  // const eventByRoleUrl = "http://localhost:5050/api/v1/admin/events";
  const eventByRoleUrl = "https://blogapi.chamsaccess.com/api/v1/admin/events";
  const fetchEventsByRole = async () => {
    const response = await axios.get(eventByRoleUrl, {
      headers: {
        Authorization: `Bearer ${user.token}`,
        "Content-Type": "application/json",
        Accept: "*",
        mode: "cors",
      },
    });
    // console.log(response.data.data);
    setEventData(response.data.data);
  };

  // DELETE - Admin Removes a specific user
  const deleteUser = async (id) => {
    try {
      await axios
        // .delete(`http://localhost:5050/api/v1/users/${id}`, {
        .delete(`https://blogapi.chamsaccess.com/api/v1/users/${id}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
            Accept: "*",
            mode: "cors",
          },
        })
        .then(() => {
          window.location.reload(true);
        });
    } catch (e) {
      console.log(e.message);
    }
  };

  // DELETE - Removes blog from the database by either admin or superadmin
  const deleteBlogsByRole = async (id) => {
    try {
      await axios
        // .delete(`http://localhost:5050/api/v1/admin/blogs/${id}`, {
        .delete(`https://blogapi.chamsaccess.com/api/v1/admin/blogs/${id}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
            Accept: "*",
            mode: "cors",
          },
        })
        .then(() => {
          window.location.reload(true);
        });
    } catch (e) {
      console.log(e.message);
    }
  };
  // DELETE - Removes event from the database by either admin or superadmin
  const deleteEventsByRole = async (id) => {
    try {
      await axios
        // .delete(`http://localhost:5050/api/v1/admin/events/${id}`, {
        .delete(`https://blogapi.chamsaccess.com/api/v1/admin/events/${id}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
            Accept: "*",
            mode: "cors",
          },
        })
        .then(() => {
          window.location.reload(true);
        });
    } catch (e) {
      console.log(e.message);
    }
  };

  const {
    register: userRegister,
    handleSubmit: userHandleSubmit,
    formState: { errors: userErrors },
    reset: userReset,
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const navigate = useNavigate();

  // function for submitting the edit user form
  // const onSubmit = handleSubmit(async (value, id) => {
  const onEditUser = userHandleSubmit(async (value, id) => {
    // console.log(value);
    const { email, password, username, role } = value;
    if (userErrors.password) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    try {
      const { data } = await axios({
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
          Accept: "*",
          mode: "cors",
        },
        // url: `http://localhost:5050/api/v1/users/${id}`,
        url: `https://blogapi.chamsaccess.com/api/v1/users/${id}`,
        method: "patch",
        data: {
          username: username,
          email: email,
          password: password,
          role: role,
        },
      });
      Swal.fire({
        position: "center",
        icon: "success",
        // title: data.responseMessage,
        title: "Successful Registration",
        text: "Login to access the dashboard",
        allowOutsideClick: true,
        allowEscapeKey: false,
      }).then(() => {
        setLoading(false);
        dispatch(user(data));
        // navigate("/");
        // window.location.reload(true);
      });
      // console.log(data);
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Oops...",
        text: "Incorrect Credentials",
        showConfirmButton: true,
        allowOutsideClick: true,
        allowEscapeKey: false,
        // timer: 2000,
      }).then(() => {
        userReset();
        // navigate("/");
        window.location.reload(true);
      });
      console.log(error);
    }
  });

  // create a post
  // function for submitting post
  const onCreatePost = async () => {
    const formData = new FormData();
    formData.append("label", label);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("content", content);
    formData.append("captionImage", captionImage);

    // Validate form data
    const validationErrors = [];
    if (label.trim() === "") {
      validationErrors.push("Label is required.");
    }
    if (title.trim() === "") {
      validationErrors.push("Title is required.");
    }
    if (description.trim() === "") {
      validationErrors.push("Description is required.");
    }
    if (content.trim() === "") {
      validationErrors.push("Content is required.");
    }
    if (!captionImage) {
      validationErrors.push("CaptionImage is required.");
    }

    if (validationErrors.length > 0) {
      setBlogErrors(validationErrors);
      return;
    }

    // Clear any previous errors
    setBlogErrors([]);

    try {
      setLoading(true);
      // const url = "http://localhost:5050/api/v1/admin/blogs";
      const url = "https://blogapi.chamsaccess.com/api/v1/admin/blogs";

      const response = await axios({
        headers: {
          Authorization: `Bearer ${user.token}`,
          Accept: "*",
          mode: "cors",
        },
        url: url,
        method: "post",
        data: formData,
      });
      console.log(response);
      if (response.status === 201) {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Successful",
          text: "Blog post created successfully",
          showConfirmButton: true,
        }).then(() => {
          window.location.reload(true);
        });
      } else {
        Swal.fire({
          position: "center-center",
          icon: "error",
          title: "Oops...",
          text: "Message failed to submit",
          showConfirmButton: true,
        });
        console.log(response.status);
        // navigate("/");
      }
    } catch (error) {
      setLoading(false);
      console.error(error.message);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("eventTitle", eventTitle);
    formData.append("eventContent", content);
    // formData.append("eventContent", eventContent);
    formData.append("titleImage", titleImage);
    eventImages.forEach((image) => {
      formData.append("eventImages", image);
      formData.append("eventVideo", eventVideo)
    });

    // Validate form data
    const validationErrors = [];
    if (eventTitle.trim() === "") {
      validationErrors.push("Event Title is required.");
    }
    if (content.trim() === "") {
      validationErrors.push("Event Content is required.");
    }
    if (!titleImage) {
      validationErrors.push("Title Image is required.");
    }
    if (eventImages.length === 0) {
      validationErrors.push("At least one Event Image is required.");
    }

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Clear any previous errors
    setErrors([]);

    try {
      setLoading(true);
      // const url = "http://localhost:5050/api/v1/admin/events";
      const url = "https://blogapi.chamsaccess.com/api/v1/admin/events";

      const response = await axios({
        headers: {
          Authorization: `Bearer ${user.token}`,
          Accept: "*",
          mode: "cors",
        },
        url: url,
        method: "post",
        data: formData,
      });
      console.log(response);
      if (response.status === 201) {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Successful",
          text: "Event post created successfully",
          showConfirmButton: true,
        }).then(() => {
          window.location.reload(true);
        });
      } else {
        Swal.fire({
          position: "center-center",
          icon: "error",
          title: "Oops...",
          text: "Message failed to submit",
          showConfirmButton: true,
        });
        console.log(response.status);
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      console.error(error.message);
    }
  };

  // edit a post
  // function for submitting post
  const onEditPost = async (id) => {
    const formData = new FormData();
    formData.append("label", label);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("content", content);
    formData.append("captionImage", captionImage);

    // console.log(dataForm);
    try {
      setLoading(true);
      // const url = `http://localhost:5050/api/v1/admin/blogs/${id}`;
      const url = `https://blogapi.chamsaccess.com/api/v1/admin/blogs/${id}`;
      const response = await axios({
        headers: {
          Authorization: `Bearer ${user.token}`,
          Accept: "*",
          mode: "cors",
        },
        url: url,
        method: "put",
        data: formData,
      });
      console.log(response);
      if (response.status === 200) {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Successful",
          text: "Blog post updated successfully",
          showConfirmButton: true,
        }).then(() => {
          window.location.reload(true);
        });
      } else {
        Swal.fire({
          position: "center-center",
          icon: "error",
          title: "Oops...",
          text: "Message failed to submit",
          showConfirmButton: true,
        });
        console.log(response.status);
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      console.error(error.message);
    }
  };

  const onEditEvent = async (id) => {
    const formData = new FormData();
    formData.append("eventTitle", eventTitle);
    formData.append("eventContent", content);
    // formData.append("eventContent", eventContent);
    formData.append("titleImage", titleImage);
    eventImages.forEach((image) => {
      formData.append("eventImages", image);
    });

    try {
      setLoading(true);
      // const url = `http://localhost:5050/api/v1/admin/events/${id}`;
      const url = `https://blogapi.chamsaccess.com/api/v1/admin/events/${id}`;
      const response = await axios({
        headers: {
          Authorization: `Bearer ${user.token}`,
          Accept: "*",
          mode: "cors",
        },
        url: url,
        method: "put",
        data: formData,
      });
      console.log(response);
      if (response.status === 200) {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Successful",
          text: "Event post updated successfully",
          showConfirmButton: true,
        }).then(() => {
          window.location.reload(true);
        });
      } else {
        Swal.fire({
          position: "center-center",
          icon: "error",
          title: "Oops...",
          text: "Message failed to submit",
          showConfirmButton: true,
        });
        console.log(response.status);
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      console.error(error.message);
    }
  };

  // loader CSS
  const override = {
    width: "inherit",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  };

  const imgStyle = {
    marginLeft: "40px",
  };

  useEffect(() => {
    fetchAllBlogs();
    fetchAllEvents();
    fetchBlogsByRole();
    fetchEventsByRole();
    fetchAllUsers();
  }, []);

  return (
    <>
      {user ? (
        <DashboardWrapper>
          <Header>
            <img style={imgStyle} src={chamsLogo} alt="company logo" />
            <h3>Welcome to chamsaccess blog portal</h3>
            <AdminInfo>
              <img src={adminAvatar} alt="admin avatar" />
              <div>{decode && decode.username}</div>
            </AdminInfo>
          </Header>

          <NavWrapper>
            <Nav>
              <AdminInfo>
                <img src={adminAvatar} alt="admin avatar" />
                <div>{decode.username}</div>
              </AdminInfo>
              {decode.role === "superadmin" ? (
                <Tab
                  onClick={() => {
                    handleTabClick("all-blogs");
                    fetchAllBlogs();
                  }}
                  active={activeTab === "all-blogs"}
                >
                  All Blogs
                </Tab>
              ) : null}
              <Tab
                onClick={() => {
                  handleTabClick("my-blogs");
                  fetchBlogsByRole();
                }}
                active={activeTab === "my-blogs"}
              >
                My Blogs
              </Tab>
              <Tab
                onClick={() => handleTabClick("post-blog")}
                active={activeTab === "post-blog"}
              >
                Post Blog
              </Tab>
              {/* start of events nav */}
              {decode.role === "superadmin" ? (
                <Tab
                  onClick={() => {
                    handleTabClick("all-events");
                    fetchAllEvents();
                  }}
                  active={activeTab === "all-events"}
                >
                  All Events
                </Tab>
              ) : null}
              <Tab
                onClick={() => {
                  handleTabClick("my-events");
                  fetchEventsByRole();
                }}
                active={activeTab === "my-events"}
              >
                My Events
              </Tab>
              <Tab
                onClick={() => {
                  handleTabClick("post-event");
                }}
                active={activeTab === "post-event"}
              >
                Post Event
              </Tab>
              {/* end of events nav */}
              {decode.role === "superadmin" ? (
                <Tab
                  onClick={() => {
                    handleTabClick("view-admins");
                    fetchAllUsers();
                  }}
                  active={activeTab === "view-admins"}
                >
                  View Admins
                </Tab>
              ) : null}
              {decode.role === "superadmin" ? (
                <Tab
                  onClick={() => handleTabClick("add-admin")}
                  active={activeTab === "add-admin"}
                >
                  Add Admin
                </Tab>
              ) : null}
              <Tab
                onClick={() => {
                  handleTabClick("logout");
                  dispatch(signOut(user));
                }}
                active={activeTab === "logout"}
              >
                Logout
              </Tab>
            </Nav>
            <Body>
              <>
                {activeTab === "all-blogs" && (
                  <div>
                    <h2>All Users Posts</h2>
                    <BlogTable>
                      <thead>
                        <tr>
                          <th>Label</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Content</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {blogData.map((blog) => (
                          <tr key={blog._id}>
                            <td>{blog.label}</td>
                            <td>{blog.title}</td>
                            <td>{blog.description}</td>
                            <td>{parse(blog.content)}</td>
                            <td>
                              <button
                                onClick={() => {
                                  // console.log(blog._id);
                                  deleteBlogsByRole(blog._id);
                                }}
                              >
                                <img src={deleteIcon} alt="deletePost" />
                              </button>
                              {decode.role === "superadmin" ? (
                                <button
                                  onClick={() => {
                                    handleEditButtonClick();
                                    // console.log(user);
                                  }}
                                >
                                  <img src={editIcon} alt="editPost" />
                                </button>
                              ) : null}
                              {/* start of modal */}
                              {isModalOpen && (
                                <ModalOverlay>
                                  <ModalWrapper>
                                    <ModalHeader>
                                      <h3>Edit Blog</h3>
                                      <button
                                        onClick={() => {
                                          handleCloseModal();
                                        }}
                                      >
                                        Close
                                      </button>
                                    </ModalHeader>
                                    {/* additional form fields and submit button go here */}
                                    {/* <Registration /> */}

                                    <>
                                      {loading ? (
                                        <ClipLoader
                                          color={"green"}
                                          loading={loading}
                                          cssOverride={override}
                                          size={10}
                                        />
                                      ) : (
                                        <FormContainer onSubmit={onEditPost}>
                                          <NonForm>
                                            <img
                                              src={chamsLogo}
                                              alt="company logo"
                                            />
                                            <AdminHeader>
                                              update Blog
                                            </AdminHeader>
                                            <AdminAccess>
                                              You must be either an admin or a
                                              superadmin
                                            </AdminAccess>
                                          </NonForm>
                                          <Form
                                            onSubmit={() => {
                                              onEditEvent(blog._id);
                                            }}
                                          >
                                            <EditBlogFormWrapper>
                                              <label>
                                                Caption Image
                                                {errors.length > 0 && (
                                                  <div style={{ color: "red" }}>
                                                    <p>{errors[2]}</p>
                                                  </div>
                                                )}{" "}
                                              </label>
                                              <input
                                                type="file"
                                                id="captionImage"
                                                onChange={
                                                  handleCaptionmageChange
                                                }
                                                style={{
                                                  width: "100%",
                                                  marginTop: "10px",
                                                  // backgroundColor: "red",
                                                }}
                                              />
                                              <label>
                                                Label
                                                {errors.length > 0 && (
                                                  <div style={{ color: "red" }}>
                                                    <p>{errors[2]}</p>
                                                  </div>
                                                )}
                                                <input
                                                  type="text"
                                                  placeholder="Enter your label"
                                                  id="label"
                                                  onChange={handleLabelChange}
                                                  defaultValue={blog.label}
                                                />
                                              </label>
                                              <label>
                                                Title
                                                {errors.length > 0 && (
                                                  <div style={{ color: "red" }}>
                                                    <p>{errors[2]}</p>
                                                  </div>
                                                )}
                                                <input
                                                  type="text"
                                                  placeholder="Enter you title"
                                                  id="title"
                                                  onChange={handTitleChange}
                                                  defaultValue={blog.title}
                                                />
                                              </label>
                                              <label>
                                                Description
                                                {errors.length > 0 && (
                                                  <div style={{ color: "red" }}>
                                                    <p>{errors[2]}</p>
                                                  </div>
                                                )}
                                                <input
                                                  type="text"
                                                  placeholder="Enter your blog description"
                                                  id="description"
                                                  onChange={
                                                    handDescriptionChange
                                                  }
                                                  defaultValue={
                                                    blog.description
                                                  }
                                                />
                                              </label>
                                              <div
                                                style={{
                                                  // backgroundColor: "red",
                                                  width: "100%",
                                                  height: "150px",
                                                  marginBottom: "50px",
                                                }}
                                              >
                                                <label>
                                                  Content
                                                  {errors.length > 0 && (
                                                    <div
                                                      style={{ color: "red" }}
                                                    >
                                                      <p>{errors[2]}</p>
                                                    </div>
                                                  )}
                                                </label>{" "}
                                                <ReactQuill
                                                  theme="snow"
                                                  id="content"
                                                  name="content"
                                                  onChange={handleQuillChange}
                                                  defaultValue={blog.content}
                                                  style={{
                                                    width: "100%",
                                                    height: "120px",
                                                    // background: "blue",
                                                    marginTop: "10px",
                                                  }}
                                                />
                                              </div>
                                              <LoginButton
                                                disabled={loading}
                                                type="submit"
                                                onClick={() => {
                                                  onEditPost(blog._id);
                                                  console.log(blog);
                                                }}
                                              >
                                                {loading
                                                  ? "Loading......"
                                                  : "Submit"}
                                              </LoginButton>
                                              <LoginLocked>
                                                <img
                                                  src={keyLock}
                                                  alt="key lock"
                                                />
                                                <p>Locked for non superadmin</p>
                                              </LoginLocked>
                                            </EditBlogFormWrapper>
                                          </Form>
                                        </FormContainer>
                                      )}
                                    </>
                                  </ModalWrapper>
                                </ModalOverlay>
                              )}
                              {/* end of modal */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </BlogTable>
                  </div>
                )}
              </>
              <>
                {activeTab === "all-events" && (
                  <div>
                    <h2>All Events</h2>
                    <BlogTable>
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Content</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {eventData.map((event) => (
                          <tr key={event._id}>
                            <td>{event.eventTitle}</td>
                            <td>{parse(event.eventContent)}</td>
                            <td>
                              <button
                                onClick={() => {
                                  deleteEventsByRole(event._id);
                                }}
                              >
                                <img src={deleteIcon} alt="deleteEvent" />
                              </button>
                              <button
                                onClick={() => {
                                  handleEditButtonClick();
                                  // console.log(user);
                                }}
                              >
                                <img src={editIcon} alt="editPost" />
                              </button>
                              {/* start of modal */}
                              {isModalOpen && (
                                <ModalOverlay>
                                  <ModalWrapper>
                                    <ModalHeader>
                                      <h3>Edit Event</h3>
                                      <button
                                        onClick={() => {
                                          handleCloseModal();
                                        }}
                                      >
                                        Close
                                      </button>
                                    </ModalHeader>
                                    {/* additional form fields and submit button go here */}
                                    {/* <Registration /> */}
                                    <>
                                      {loading ? (
                                        <ClipLoader
                                          color={"green"}
                                          loading={loading}
                                          cssOverride={override}
                                          size={10}
                                        />
                                      ) : (
                                        <FormContainer onSubmit={onEditEvent}>
                                          <NonForm>
                                            <img
                                              src={chamsLogo}
                                              alt="company logo"
                                            />
                                            <AdminHeader>
                                              Update All Event
                                            </AdminHeader>
                                            <AdminAccess>
                                              You must be either an admin or a
                                              superadmin
                                            </AdminAccess>
                                          </NonForm>
                                          <Form
                                            onSubmit={() => {
                                              onEditEvent(event._id);
                                            }}
                                          >
                                            <EditEventFormWrapper
                                              style={{
                                                marginTop: "0px",
                                                marginBottom: "0px",
                                              }}
                                            >
                                              <label>Title Image</label>
                                              <input
                                                type="file"
                                                accept="image/*"
                                                onChange={
                                                  handleTitleImageChange
                                                }
                                                style={{
                                                  width: "100%",
                                                  marginTop: "10px",
                                                  // backgroundColor: "red",
                                                }}
                                              />
                                              <label>
                                                Event Title:
                                                <input
                                                  type="text"
                                                  onChange={handleTitleChange}
                                                  defaultValue={
                                                    event.eventTitle
                                                  }
                                                />
                                              </label>
                                              <div
                                                style={{
                                                  width: "100%",
                                                  height: "150px",
                                                  marginBottom: "50px",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    marginTop: "30px",
                                                  }}
                                                >
                                                  Content
                                                </label>{" "}
                                                <ReactQuill
                                                  theme="snow"
                                                  id="content"
                                                  name="content"
                                                  onChange={handleQuillChange}
                                                  style={{
                                                    width: "100%",
                                                    height: "120px",
                                                    // background: "blue",
                                                    marginTop: "10px",
                                                  }}
                                                />
                                              </div>
                                              <div
                                                style={{
                                                  marginTop: "30px",
                                                }}
                                              >
                                                <label>Event Images </label>
                                                <input
                                                  type="file"
                                                  accept="image/*"
                                                  multiple
                                                  onChange={
                                                    handleEventImagesChange
                                                  }
                                                  style={{
                                                    width: "100%",
                                                    marginTop: "10px",
                                                    // backgroundColor: "red",
                                                  }}
                                                />
                                              </div>
                                              <LoginButton
                                                disabled={loading}
                                                type="submit"
                                                onClick={() => {
                                                  onEditEvent(event._id);
                                                  console.log(event);
                                                }}
                                              >
                                                {loading
                                                  ? "Loading......"
                                                  : "Submit"}
                                              </LoginButton>
                                              <LoginLocked>
                                                <img
                                                  src={keyLock}
                                                  alt="key lock"
                                                />
                                                <p>Locked for non superadmin</p>
                                              </LoginLocked>
                                            </EditEventFormWrapper>
                                          </Form>
                                        </FormContainer>
                                      )}
                                    </>
                                  </ModalWrapper>
                                </ModalOverlay>
                              )}
                              {/* end of modal */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </BlogTable>
                  </div>
                )}
              </>
              {decode.role === "admin" || decode.role === "superadmin" ? (
                <>
                  {activeTab === "my-blogs" && (
                    <div>
                      <h2>My Blog Posts</h2>
                      <BlogTable>
                        <thead>
                          <tr>
                            <th>Label</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Content</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {blogData.map((blog) => (
                            <tr key={blog._id}>
                              <td>{blog.label}</td>
                              <td>{blog.title}</td>
                              <td>{blog.description}</td>
                              <td>{parse(blog.content)}</td>

                              <td>
                                <button
                                  onClick={() => {
                                    // console.log(blog._id);
                                    deleteBlogsByRole(blog._id);
                                  }}
                                >
                                  <img src={deleteIcon} alt="deletePost" />
                                </button>
                                <button
                                  onClick={() => {
                                    handleEditButtonClick(blog._id);
                                    console.log(blog._id);
                                  }}
                                >
                                  <img src={editIcon} alt="editPost" />
                                </button>
                                {/* start of modal */}
                                {isModalOpen && (
                                  <ModalOverlay>
                                    <ModalWrapper>
                                      <ModalHeader>
                                        <h3>Edit Blog</h3>
                                        <button
                                          onClick={() => {
                                            handleCloseModal();
                                            window.location.reload(true);
                                          }}
                                        >
                                          Close
                                        </button>
                                      </ModalHeader>
                                      {/* additional form fields and submit button go here */}
                                      {/* <Registration /> */}

                                      <>
                                        {loading ? (
                                          <ClipLoader
                                            color={"green"}
                                            loading={loading}
                                            cssOverride={override}
                                            size={10}
                                          />
                                        ) : (
                                          <FormContainer onSubmit={onEditPost}>
                                            <NonForm>
                                              <img
                                                src={chamsLogo}
                                                alt="company logo"
                                              />
                                              <AdminHeader>
                                                update Blog
                                              </AdminHeader>
                                              <AdminAccess>
                                                You must be either an admin or a
                                                superadmin
                                              </AdminAccess>
                                            </NonForm>
                                            <Form
                                              onSubmit={() => {
                                                onEditEvent(blog._id);
                                              }}
                                            >
                                              <EditBlogFormWrapper>
                                                <label>
                                                  Caption
                                                  {errors.length > 0 && (
                                                    <div
                                                      style={{ color: "red" }}
                                                    >
                                                      <p>{errors[2]}</p>
                                                    </div>
                                                  )}{" "}
                                                  Image
                                                </label>
                                                <input
                                                  type="file"
                                                  id="captionImage"
                                                  onChange={
                                                    handleCaptionmageChange
                                                  }
                                                  style={{
                                                    width: "100%",
                                                    marginTop: "10px",
                                                    // backgroundColor: "red",
                                                  }}
                                                />
                                                <label>
                                                  Label
                                                  {errors.length > 0 && (
                                                    <div
                                                      style={{ color: "red" }}
                                                    >
                                                      <p>{errors[2]}</p>
                                                    </div>
                                                  )}
                                                  <input
                                                    type="text"
                                                    placeholder="Enter your label"
                                                    id="label"
                                                    onChange={handleLabelChange}
                                                    defaultValue={blog.label}
                                                  />
                                                </label>
                                                <label>
                                                  Title
                                                  {errors.length > 0 && (
                                                    <div
                                                      style={{ color: "red" }}
                                                    >
                                                      <p>{errors[2]}</p>
                                                    </div>
                                                  )}
                                                  <input
                                                    type="text"
                                                    placeholder="Enter you title"
                                                    id="title"
                                                    onChange={handTitleChange}
                                                    defaultValue={blog.title}
                                                  />
                                                </label>
                                                <label>
                                                  Description
                                                  {errors.length > 0 && (
                                                    <div
                                                      style={{ color: "red" }}
                                                    >
                                                      <p>{errors[2]}</p>
                                                    </div>
                                                  )}
                                                  <input
                                                    type="text"
                                                    placeholder="Enter your blog description"
                                                    id="description"
                                                    onChange={
                                                      handDescriptionChange
                                                    }
                                                    defaultValue={
                                                      blog.description
                                                    }
                                                  />
                                                </label>
                                                <div
                                                  style={{
                                                    // backgroundColor: "red",
                                                    width: "100%",
                                                    height: "150px",
                                                    marginBottom: "50px",
                                                  }}
                                                >
                                                  <label>
                                                    Content
                                                    {errors.length > 0 && (
                                                      <div
                                                        style={{ color: "red" }}
                                                      >
                                                        <p>{errors[2]}</p>
                                                      </div>
                                                    )}
                                                  </label>{" "}
                                                  <ReactQuill
                                                    theme="snow"
                                                    id="content"
                                                    name="content"
                                                    onChange={handleQuillChange}
                                                    defaultValue={blog.content}
                                                    style={{
                                                      width: "100%",
                                                      height: "120px",
                                                      // background: "blue",
                                                      marginTop: "10px",
                                                    }}
                                                  />
                                                </div>
                                                <LoginButton
                                                  disabled={loading}
                                                  type="submit"
                                                  onClick={() => {
                                                    onEditPost(blog._id);
                                                    console.log(blog);
                                                  }}
                                                >
                                                  {loading
                                                    ? "Loading......"
                                                    : "Submit"}
                                                </LoginButton>
                                                <LoginLocked>
                                                  <img
                                                    src={keyLock}
                                                    alt="key lock"
                                                  />
                                                  <p>
                                                    Locked for non superadmin
                                                  </p>
                                                </LoginLocked>
                                              </EditBlogFormWrapper>
                                            </Form>
                                          </FormContainer>
                                        )}
                                      </>
                                    </ModalWrapper>
                                  </ModalOverlay>
                                )}
                                {/* end of modal */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </BlogTable>
                    </div>
                  )}
                </>
              ) : null}
              {decode.role === "admin" || decode.role === "superadmin" ? (
                <>
                  {activeTab === "my-events" && (
                    <div>
                      <h2>My Events</h2>
                      <BlogTable>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Content</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {eventData.map((event) => (
                            <tr key={event._id}>
                              <td>{event.eventTitle}</td>
                              <td>{parse(event.eventContent)}</td>
                              <td>
                                <button
                                  onClick={() => {
                                    // console.log(event._id);
                                    deleteEventsByRole(event._id);
                                  }}
                                >
                                  <img src={deleteIcon} alt="deleteEvent" />
                                </button>
                                <button
                                  onClick={() => {
                                    handleEditButtonClick();
                                    console.log(event._id);
                                  }}
                                >
                                  <img src={editIcon} alt="editEvent" />
                                </button>
                                {/* start of modal */}
                                {isModalOpen && (
                                  <ModalOverlay>
                                    <ModalWrapper>
                                      <ModalHeader>
                                        <h3>Edit Event</h3>
                                        <button
                                          onClick={() => {
                                            handleCloseModal();
                                            window.location.reload(true);
                                          }}
                                        >
                                          Close
                                        </button>
                                      </ModalHeader>
                                      {/* additional form fields and submit button go here */}
                                      {/* <Registration /> */}

                                      <>
                                        {loading ? (
                                          <ClipLoader
                                            color={"green"}
                                            loading={loading}
                                            cssOverride={override}
                                            size={10}
                                          />
                                        ) : (
                                          <FormContainer onSubmit={onEditEvent}>
                                            <NonForm>
                                              <img
                                                src={chamsLogo}
                                                alt="company logo"
                                              />
                                              <AdminHeader>
                                                Update My Event
                                              </AdminHeader>
                                              <AdminAccess>
                                                You must be either an admin or a
                                                superadmin
                                              </AdminAccess>
                                            </NonForm>
                                            <Form
                                              onSubmit={() => {
                                                onEditEvent(event._id);
                                              }}
                                            >
                                              <EditEventFormWrapper
                                                style={{
                                                  marginTop: "0px",
                                                  marginBottom: "0px",
                                                }}
                                              >
                                                <label>Title Image</label>
                                                <input
                                                  type="file"
                                                  accept="image/*"
                                                  onChange={
                                                    handleTitleImageChange
                                                  }
                                                  style={{
                                                    width: "100%",
                                                    marginTop: "10px",
                                                    // backgroundColor: "red",
                                                  }}
                                                />
                                                <label>
                                                  Event Title:
                                                  <input
                                                    type="text"
                                                    onChange={handleTitleChange}
                                                    defaultValue={
                                                      event.eventTitle
                                                    }
                                                  />
                                                </label>
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    height: "150px",
                                                    marginBottom: "50px",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      marginTop: "30px",
                                                    }}
                                                  >
                                                    Content
                                                  </label>{" "}
                                                  <ReactQuill
                                                    theme="snow"
                                                    id="content"
                                                    name="content"
                                                    onChange={handleQuillChange}
                                                    defaultValue={
                                                      event.eventContent
                                                    }
                                                    style={{
                                                      width: "100%",
                                                      height: "120px",
                                                      // background: "yellow",
                                                      marginTop: "0px",
                                                    }}
                                                  />
                                                </div>
                                                <div
                                                  style={{
                                                    marginTop: "30px",
                                                  }}
                                                >
                                                  <label>Event Images </label>
                                                  <input
                                                    type="file"
                                                    accept="image/*"
                                                    multiple
                                                    onChange={
                                                      handleEventImagesChange
                                                    }
                                                    style={{
                                                      width: "100%",
                                                      marginTop: "10px",
                                                      // backgroundColor: "red",
                                                    }}
                                                  />
                                                </div>
                                                <LoginButton
                                                  disabled={loading}
                                                  type="submit"
                                                  onClick={() => {
                                                    onEditEvent(event._id);
                                                    console.log(event);
                                                  }}
                                                >
                                                  {loading
                                                    ? "Loading......"
                                                    : "Submit"}
                                                </LoginButton>
                                                <LoginLocked>
                                                  <img
                                                    src={keyLock}
                                                    alt="key lock"
                                                  />
                                                  <p>
                                                    Locked for non superadmin
                                                  </p>
                                                </LoginLocked>
                                              </EditEventFormWrapper>
                                            </Form>
                                          </FormContainer>
                                        )}
                                      </>
                                    </ModalWrapper>
                                  </ModalOverlay>
                                )}
                                {/* end of modal */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </BlogTable>
                    </div>
                  )}
                </>
              ) : null}
              {activeTab === "post-blog" && (
                // start to create a blog form
                <>
                  {loading ? (
                    <ClipLoader
                      color={"green"}
                      loading={loading}
                      cssOverride={override}
                      size={10}
                    />
                  ) : (
                    <FormContainer onSubmit={onCreatePost}>
                      <NonForm>
                        <img src={chamsLogo} alt="company logo" />
                        <AdminHeader>Post Blog</AdminHeader>
                        <AdminAccess>
                          You must be either an admin or a superadmin
                        </AdminAccess>
                      </NonForm>
                      <Form
                        onSubmit={() => {
                          onCreatePost();
                        }}
                      >
                        <EditBlogFormWrapper>
                          <label>
                            Caption Image
                            {blogErrors.length > 0 && (
                              <div style={{ color: "red" }}>
                                <p>{blogErrors[4]}</p>
                              </div>
                            )}{" "}
                          </label>
                          <input
                            type="file"
                            id="captionImage"
                            onChange={handleCaptionmageChange}
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              // backgroundColor: "red",
                            }}
                          />
                          <label>
                            Label
                            {blogErrors.length > 0 && (
                              <div style={{ color: "red" }}>
                                <p>{blogErrors[0]}</p>
                              </div>
                            )}
                            <input
                              type="text"
                              placeholder="Enter your label"
                              id="label"
                              onChange={handleLabelChange}
                            />
                          </label>
                          <label>
                            Title
                            {blogErrors.length > 0 && (
                              <div style={{ color: "red" }}>
                                <p>{blogErrors[1]}</p>
                              </div>
                            )}
                            <input
                              type="text"
                              placeholder="Enter you title"
                              id="title"
                              onChange={handTitleChange}
                            />
                          </label>
                          <label>
                            Description
                            {blogErrors.length > 0 && (
                              <div style={{ color: "red" }}>
                                <p>{blogErrors[2]}</p>
                              </div>
                            )}
                            <input
                              type="text"
                              placeholder="Enter your blog description"
                              id="description"
                              onChange={handDescriptionChange}
                            />
                          </label>
                          <div
                            style={{
                              // backgroundColor: "red",
                              width: "100%",
                              height: "190px",
                              marginBottom: "40px",
                            }}
                          >
                            <label>
                              Content
                              {blogErrors.length > 0 && (
                                <div style={{ color: "red" }}>
                                  <p>{blogErrors[3]}</p>
                                </div>
                              )}
                            </label>{" "}
                            <ReactQuill
                              theme="snow"
                              id="content"
                              name="content"
                              onChange={handleQuillChange}
                              style={{
                                width: "100%",
                                height: "120px",
                                // background: "blue",
                                marginTop: "10px",
                              }}
                            />
                          </div>
                          <LoginButton
                            disabled={loading}
                            type="submit"
                            onClick={() => {
                              onCreatePost();
                              console.log("clicked");
                            }}
                          >
                            {loading ? "Loading......" : "Submit"}
                          </LoginButton>
                          <LoginLocked>
                            <img src={keyLock} alt="key lock" />
                            <p>Locked for non superadmin</p>
                          </LoginLocked>
                        </EditBlogFormWrapper>
                      </Form>
                    </FormContainer>
                  )}
                </>
                // end of creating from.
              )}
              {activeTab === "post-event" && (
                // start to create an event form
                <>
                  {loading ? (
                    <ClipLoader
                      color={"green"}
                      loading={loading}
                      cssOverride={override}
                      size={10}
                    />
                  ) : (
                    <FormContainer>
                      <NonForm>
                        <img src={chamsLogo} alt="company logo" />
                        <AdminHeader>Post Event</AdminHeader>
                        <AdminAccess>
                          You must be either an admin or a superadmin
                        </AdminAccess>
                      </NonForm>
                      <Form onSubmit={handleSubmit}>
                        <EditEventFormWrapper>
                          <label>
                            Title Image
                            {errors.length > 0 && (
                              <div style={{ color: "red" }}>
                                <p>{errors[2]}</p>
                              </div>
                            )}
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleTitleImageChange}
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              // backgroundColor: "red",
                            }}
                          />
                          <label>
                            Event Title:
                            {errors.length > 0 && (
                              <div style={{ color: "red" }}>
                                <p>{errors[0]}</p>
                              </div>
                            )}
                            <input
                              type="text"
                              value={eventTitle}
                              onChange={handleTitleChange}
                              placeholder="Enter a title"
                            />
                          </label>
                          <div
                            style={{
                              // backgroundColor: "red",
                              width: "100%",
                              height: "150px",
                              marginTop: "30px",
                              marginBottom: "50px",
                            }}
                          >
                            <label
                              style={{
                                marginTop: "0px",
                              }}
                            >
                              Content
                              {errors.length > 0 && (
                                <div style={{ color: "red" }}>
                                  <p>{errors[1]}</p>
                                </div>
                              )}
                            </label>{" "}
                            <ReactQuill
                              theme="snow"
                              id="content"
                              name="content"
                              // value={content}
                              onChange={handleQuillChange}
                              // {...register("eventContent")}

                              style={{
                                width: "100%",
                                height: "120px",
                                // background: "yellow",
                                marginTop: "10px",
                              }}
                            />
                          </div>
                          <label>
                            Event Images
                            {errors.length > 0 && (
                              <div style={{ color: "red" }}>
                                <p
                                  style={{
                                    marginBottom: "0px",
                                  }}
                                >
                                  {errors[3]}
                                </p>
                              </div>
                            )}
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleEventImagesChange}
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              // backgroundColor: "red",
                            }}
                          />
                          <label>
                            Event Videos
                            {errors.length > 0 && (
                              <div style={{ color: "red" }}>
                                <p
                                  style={{
                                    marginBottom: "0px",
                                  }}
                                >
                                  {errors[3]}
                                </p>
                              </div>
                            )}
                          </label>
                          <input
                            type="file"
                            accept="video/*"
                            multiple
                            onChange={handleEventVideoChange}
                            style={{
                              width: "100%",
                              marginTop: "10px",
                            }}
                          />
                          <br />
                          {/* array of errors */}
                          {/* {errors.length > 0 && (
                            <div style={{ color: "red" }}>
                              <p>{console.log(errors)}</p>
                              {errors.map((error, index) => (
                                <p key={index}>{error}</p>
                              ))}
                            </div>
                          )} */}
                          <LoginButton
                            disabled={loading}
                            type="submit"
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            {loading ? "Loading......" : "Submit"}
                          </LoginButton>
                          <LoginLocked>
                            <img src={keyLock} alt="key lock" />
                            <p>Locked for non superadmin</p>
                          </LoginLocked>
                        </EditEventFormWrapper>
                      </Form>
                    </FormContainer>
                  )}
                </>
                // end of creating from.
              )}
              {activeTab === "view-admins" && (
                <div>
                  <h2>View Admins</h2>
                  <BlogTable>
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.map((user) => (
                        <tr key={user._id}>
                          <td>{user.username}</td>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                          <td>
                            <button
                              onClick={() => {
                                deleteUser(user._id);
                              }}
                            >
                              <img src={deleteIcon} alt="deletePost" />
                            </button>
                            <button
                              onClick={() => {
                                handleEditButtonClick();
                                // console.log(user);
                              }}
                            >
                              <img src={editIcon} alt="editPost" />
                            </button>
                            {/* start of modal */}
                            {isModalOpen && (
                              <ModalOverlay>
                                <ModalWrapper>
                                  <ModalHeader>
                                    <h3>Edit User</h3>
                                    <button
                                      onClick={() => {
                                        handleCloseModal();
                                        window.location.reload(true);
                                      }}
                                    >
                                      Close
                                    </button>
                                  </ModalHeader>
                                  {/* additional form fields and submit button go here */}
                                  {/* <Registration /> */}

                                  <>
                                    {loading ? (
                                      <ClipLoader
                                        color={"green"}
                                        loading={loading}
                                        cssOverride={override}
                                        size={10}
                                      />
                                    ) : (
                                      <FormContainer>
                                        <NonForm>
                                          <img
                                            src={chamsLogo}
                                            alt="company logo"
                                          />
                                          <AdminHeader>
                                            Admin Registration
                                          </AdminHeader>
                                          <AdminAccess>
                                            Become an admin
                                          </AdminAccess>
                                        </NonForm>
                                        <UserForm>
                                          <Form>
                                            <label>
                                              Username
                                              <Err>
                                                {userErrors?.username?.message}
                                              </Err>
                                              <input
                                                type="text"
                                                placeholder="Enter your username"
                                                id="username"
                                                {...userRegister("username")}
                                                // value={user.username}
                                              />
                                            </label>
                                            <label>
                                              Email address
                                              <Err>
                                                {userErrors?.email?.message}
                                              </Err>
                                              <input
                                                type="email"
                                                placeholder="Enter your email address"
                                                id="email"
                                                {...userRegister("email")}
                                                // value={user.email}
                                              />
                                            </label>
                                            <label>
                                              Password
                                              <Err>
                                                {userErrors?.password?.message}
                                              </Err>
                                              <input
                                                type="password"
                                                placeholder="*************"
                                                id="password"
                                                {...userRegister("password")}
                                                // value={user.password}
                                              />
                                            </label>
                                            <label>
                                              Select a role
                                              <Err>
                                                {userErrors?.role?.message}
                                              </Err>
                                              {/* <select id="role" value={user.role}> */}
                                              <select
                                                id="role"
                                                {...userRegister("role")}
                                              >
                                                <option label=" ">
                                                  Select an option
                                                </option>
                                                <option label="user">
                                                  user
                                                </option>
                                                <option label="superadmin">
                                                  superadmin
                                                </option>
                                                <option label="admin">
                                                  admin
                                                </option>
                                              </select>
                                            </label>
                                            <LoginButton
                                              disabled={loading}
                                              type="submit"
                                              onClick={() => {
                                                onEditUser(user._id);
                                                setLoading(false);
                                              }}
                                            >
                                              {loading
                                                ? "Loading......"
                                                : "Submit"}
                                            </LoginButton>
                                            <LoginLocked>
                                              <img
                                                src={keyLock}
                                                alt="key lock"
                                              />
                                              <p>Locked for non superadmin</p>
                                            </LoginLocked>
                                          </Form>
                                        </UserForm>
                                      </FormContainer>
                                    )}
                                  </>
                                </ModalWrapper>
                              </ModalOverlay>
                            )}
                            {/* end of modal */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </BlogTable>
                </div>
              )}
              {activeTab === "add-admin" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h2>Register an admin</h2>
                  <RegWrapper>
                    <Registration />
                  </RegWrapper>
                </div>
              )}
              {activeTab === "logout" && <div>You have been logged out.</div>}
            </Body>
          </NavWrapper>
        </DashboardWrapper>
      ) : null}
    </>
  );
};

export default Index;
