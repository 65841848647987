import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import ClipLoader from "react-spinners/BeatLoader";
import { useDispatch } from "react-redux";
import {
  FormContainer,
  NonForm,
  Form,
  AdminHeader,
  AdminAccess,
  LoginButton,
  LoginLocked,
  Err,
} from "./LoginFormStyle";
import chamsLogo from "../../images/ChamsLogo.svg";
import keyLock from "../../images/shield-security.svg";

import { user } from "../../globals/Reducers/AdminReducer";
const LoginForm = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // userSignIn with react-hook-form
  const userSchema = yup.object().shape({
    email: yup.string().email().required("Enter a valid email address"),
    password: yup.string().required("Enter a valid email address"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const navigate = useNavigate();

  // function for submitting form
  const onSubmit = handleSubmit(async (value) => {
    // console.log(value);
    const { email, password } = value;
    if (errors.password) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    try {
      // const url = "http://localhost:5050/api/v1/users/login";
      const url = "https://blogapi.chamsaccess.com/api/v1/users/login";

      const { data } = await axios({
        headers: {
          "Content-Type": "application/json",
          Accept: "*",
          mode: "cors",
        },
        url: url,
        method: "post",
        data: {
          email: email,
          password: password,
        },
      });
      Swal.fire({
        position: "center",
        icon: "success",
        // title: data.responseMessage,
        title: "Login Successful",
        text: "Explore your dashboard!!!",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(() => {
        dispatch(user(data));
        // console.log(data);
        // console.log(data.token);
        navigate("/dashboard");
      });
      // console.log(data);
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Oops...",
        text: "Incorrect Credentials",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        // timer: 2000,
      }).then(() => {
        reset();
        navigate("/");
        window.location.reload(false);
      });
      console.log(error);
    }
  });

  // loader CSS
  const override = {
    width: "inherit",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  };
  return (
    <>
      {loading ? (
        <ClipLoader
          color={"green"}
          loading={loading}
          cssOverride={override}
          size={10}
        />
      ) : (
        <FormContainer>
          <NonForm>
            <img src={chamsLogo} alt="company logo" />
            <AdminHeader>Admin Login</AdminHeader>
            <AdminAccess>Login to access the dashboard</AdminAccess>
          </NonForm>
          <Form>
            <label>
              Email address
              <Err>{errors?.email?.message}</Err>
              <input
                type="email"
                placeholder="Enter your email address"
                id="email"
                {...register("email")}
              />
            </label>
            <label>
              Password
              <Err>{errors?.password?.message}</Err>
              <input
                type="password"
                placeholder="*************"
                id="password"
                {...register("password")}
              />
            </label>
            <LoginButton
              disabled={loading}
              type="submit"
              onClick={() => {
                onSubmit();
              }}
            >
              {loading ? "Loading......" : "Submit"}
            </LoginButton>
            <LoginLocked>
              <img src={keyLock} alt="key lock" />
              <p>Locked for non admin</p>
            </LoginLocked>
          </Form>
        </FormContainer>
      )}
    </>
  );
};

export default LoginForm;
