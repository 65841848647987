import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./components/login/Login";
import Index from "./components/dashboard/index";
import PrivateRoute from "./components/privateRoute/PrivateRoute";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Index />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
