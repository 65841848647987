import styled from "styled-components";

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
`;

export const NavWrapper = styled.nav`
  // width: 100vw;
  //   min-height: 100vh;
  height: auto;
  display: flex;
  //   background-color: tomato;
`;
export const Nav = styled.nav`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #eee;
  padding: 1rem;
  min-height: 100vh;
`;

export const Body = styled.main`
  //   background-color: red;
  display: flex;
  justify-content: center;
  flex: 5;
  padding: 1rem;
  height: auto;
`;

export const AdminInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  //   background-color: red;

  & img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 1rem;
  }
`;

export const Tab = styled.div`
  text-align: left;
  margin-bottom: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  font-family: "Komet";
  border-radius: 8px;
  padding-left: 20px;
  background-color: ${({ active }) => (active ? "#0b9444" : "#eee")};
  color: ${({ active }) => (active ? "#fff" : "#444")};
  transition: all 0.3s ease;

  &:hover {
    background-color: #0b9444;
    color: #fff;
    padding-left: 40px;
  }
`;

export const BlogTable = styled.table`
  width: 100%;
  width: 75vw;
  border-collapse: collapse;
  font-family: "Komet";

  thead {
    // background-color: red;
  }

  & th,
  td {
    padding: 0.5rem;
    border: 1px solid #ddd;
    // background: red;
  }

  & th:first-child td {
    background-color: red;
  }

  & th {
    text-align: center;
    color: green;
  }

  & button {
    cursor: pointer;
    border: none;
    border-radius: 2px;
    background-color: white;
    color: #ffffff;
    padding: 3px 10px;
  }

  & button img {
    height: 25px;
    width: 25px;
  }

  & tbody tr td {
    // display: flex;
    height: auto;
  }
`;

export const RegWrapper = styled.div`
  //   background-color: red;
  padding: 10px 20px;
  // max-width: 50%;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
  overflow-y: scroll;
  z-index: 999;
  //   background-color: red;
`;
export const ModalWrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  margin-top: 200px;
  margin-bottom: 60px;
`;
export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h3 {
    text-transform: uppercase;
  }

  button {
    color: #000000;
  }
`;

// form style

export const FormContainer = styled.div`
  width: 30vw;
  height: 574px;
  height: auto;
  // min-width: 468px;
  min-width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 48px;
  background: #ffffff;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 16px;

  @media screen and (max-width: 1024px) {
    // background: red;
    width: 35vw;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 45vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    height: 450px;
    width: 75vw;
    padding: 15px;
    // min-width: 10vw;
  }
  @media screen and (max-width: 375px) {
    width: 80vw;
  }
  @media screen and (max-width: 320px) {
    width: 80vw;
  }
`;
export const FormContainer2 = styled.div`
  // width: 30vw;
  height: auto;
  // min-width: 468px;
  min-width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 48px;
  background: #ffffff;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 16px;

  @media screen and (max-width: 1024px) {
    // background: red;
    width: 35vw;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 45vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    height: 450px;
    width: 75vw;
    padding: 15px;
    // min-width: 10vw;
  }
  @media screen and (max-width: 375px) {
    width: 80vw;
  }
  @media screen and (max-width: 320px) {
    width: 80vw;
  }
`;
export const NonForm = styled.div`
  // background-color: blue;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AdminHeader = styled.div`
  width: 100%;
  height: 32px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #101828;
  margin-top: 24px;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
`;
export const AdminAccess = styled.div`
  // background-color: red;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: 24px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
`;
export const Form = styled.form`
  // background-color: green;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;
export const FormWrapper = styled.div`
    // background-color: green;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;


label {
    // background: green;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #98a2b3;
    // margin-top: 32px;
    // margin-bottom: 32px;

    select {
        width: 100%;
        height: 100%;
        border: 0.5px solid grey;
        border-radius: 4px;
        padding: 8px auto;
        margin-top: 6px;
    }

    input {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      // height: 40px;
      height: auto;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      border: 0.5px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      // margin-top: 6px;
      padding: 10px 0px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #667085;
      outline: none;

      ::placeholder {
        padding-left: 10px;
      }

      :focus {
        width: 98%;
        border: 1px solid #45a049;
        padding-left: 10px;

        ::placeholder {
          padding-left: 0px;
        }
      }
    }
  }

  @media screen and (min-width: 1025px) {
    width: 100%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background: green;

    label {
      // background: yellow;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  
      width: inherit;
      height: 24px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #344054;
      margin-bottom: 30px;
  
      input {
        display: flex;
        flex-direction: row;
        align-items: center;
        // width: 280px;
        width: 100%;
        height: 40px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        margin-top: 6px;
        padding: 10px 0px;
        font-family: "Komet";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #667085;
        outline: none;
  
        ::placeholder {
          padding-left: 10px;
        }
  
        :focus {
          width: 96%;
          border: 1px solid #45a049;
          padding-left: 10px;
  
          ::placeholder {
            padding-left: 0px;
          }
        }
      }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const EditBlogFormWrapper = styled.div`
    // background-color: green;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;


label {
    // background: green;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #98a2b3;
    margin-top: 32px;
    margin-bottom: 0px;

    select {
        width: 100%;
        height: 100%;
        border: 0.5px solid grey;
        border-radius: 4px;
        padding: 8px auto;
        margin-top: 6px;
    }
  }
    input {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      // height: 40px;
      height: auto;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      border: 0.5px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      // margin-top: 6px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #667085;
      outline: none;
      margin-top: 10px;

      ::placeholder {
        // padding-left: 10px;
      }

      :focus {
        width: 98%;
        border: 1px solid #45a049;
        // padding-left: 10px;

        ::placeholder {
          // padding-left: 0px;
        }
      }
    }
  }

  @media screen and (min-width: 1025px) {
    width: 100%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background: green;

    label {
      // background: yellow;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  
      width: inherit;
      height: 24px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #344054;
      margin-bottom: 30px;
  
      input {
        display: flex;
        flex-direction: row;
        align-items: center;
        // width: 280px;
        width: 100%;
        height: 40px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        margin-top: 6px;
        padding: 10px 0px;
        font-family: "Komet";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #667085;
        outline: none;
  
        ::placeholder {
          padding-left: 10px;
        }
  
        :focus {
          width: 96%;
          border: 1px solid #45a049;
          padding-left: 10px;
  
          ::placeholder {
            padding-left: 0px;
          }
        }
      }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PostBlogFormWrapper = styled.div`
    // background-color: green;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;


label {
    // background: green;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #98a2b3;
    margin-top: 32px;
    margin-bottom: 0px;

    select {
        width: 100%;
        height: 100%;
        border: 0.5px solid grey;
        border-radius: 4px;
        padding: 8px auto;
        margin-top: 6px;
    }

    input {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      // height: 40px;
      height: auto;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      border: 0.5px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      // margin-top: 6px;
      padding: 10px 0px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #667085;
      outline: none;
      margin-top: 10px;

      ::placeholder {
        padding-left: 10px;
      }

      :focus {
        width: 98%;
        border: 1px solid #45a049;
        padding-left: 10px;

        ::placeholder {
          padding-left: 0px;
        }
      }
    }
  }

  @media screen and (min-width: 1025px) {
    width: 100%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background: green;

    label {
      // background: yellow;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  
      width: inherit;
      height: 24px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #344054;
      margin-bottom: 30px;
  
      input {
        display: flex;
        flex-direction: row;
        align-items: center;
        // width: 280px;
        width: 100%;
        height: 40px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        margin-top: 6px;
        padding: 10px 0px;
        font-family: "Komet";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #667085;
        outline: none;
  
        ::placeholder {
          padding-left: 10px;
        }
  
        :focus {
          width: 96%;
          border: 1px solid #45a049;
          padding-left: 10px;
  
          ::placeholder {
            padding-left: 0px;
          }
        }
      }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PostEventFormWrapper = styled.div`
    // background-color: green;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;


label {
    // background: green;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #98a2b3;
    margin-top: 32px;
    margin-bottom: opx;

    select {
        width: 100%;
        height: 100%;
        border: 0.5px solid grey;
        border-radius: 4px;
        padding: 8px auto;
        margin-top: 6px;
    }
  }
    input {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      // height: 40px;
      height: auto;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      border: 0.5px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      // margin-top: 6px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #667085;
      outline: none;
      margin-top: 10px;

      ::placeholder {
        padding-left: 10px;
      }

      :focus {
        width: 98%;
        border: 1px solid #45a049;
        padding-left: 10px;

        ::placeholder {
          padding-left: 0px;
        }
      }
    }
  }

  @media screen and (min-width: 1025px) {
    width: 100%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background: green;

    label {
      // background: yellow;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  
      width: inherit;
      height: 24px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #344054;
      margin-bottom: 30px;
  
      input {
        display: flex;
        flex-direction: row;
        align-items: center;
        // width: 280px;
        width: 100%;
        height: 40px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        margin-top: 6px;
        padding: 10px 0px;
        font-family: "Komet";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #667085;
        outline: none;
  
        ::placeholder {
          padding-left: 10px;
        }
  
        :focus {
          width: 96%;
          border: 1px solid #45a049;
          padding-left: 10px;
  
          ::placeholder {
            padding-left: 0px;
          }
        }
      }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const EditEventFormWrapper = styled.div`
    // background-color: green;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;


label {
    // background: green;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #98a2b3;
    margin-top: 32px;
    margin-bottom:0px;

    select {
        width: 100%;
        height: 100%;
        border: 0.5px solid grey;
        border-radius: 4px;
        padding: 8px auto;
        margin-top: 6px;
    }
  }
    input {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      // height: 40px;
      height: auto;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      border: 0.5px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      // margin-top: 6px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #667085;
      outline: none;
      margin-top: 10px;

      ::placeholder {
        // padding-left: 10px;
      }

      :focus {
        width: 98%;
        border: 1px solid #45a049;

        ::placeholder {
          padding-left: 0px;
        }
      }
    }
  }

  @media screen and (min-width: 1025px) {
    width: 100%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background: green;

    label {
      // background: yellow;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  
      width: inherit;
      height: 24px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #344054;
      margin-bottom: 30px;
  
      input {
        display: flex;
        flex-direction: row;
        align-items: center;
        // width: 280px;
        width: 100%;
        height: 40px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        margin-top: 6px;
        padding: 10px 0px;
        font-family: "Komet";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #667085;
        outline: none;
  
        ::placeholder {
          padding-left: 10px;
        }
  
        :focus {
          width: 96%;
          border: 1px solid #45a049;
          padding-left: 10px;
  
          ::placeholder {
            padding-left: 0px;
          }
        }
      }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const UserForm = styled.div`
    // background-color: green;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;


label {
    // background: green;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #98a2b3;
    margin-top: 32px;
    margin-bottom:0px;

    select {
        width: 100%;
        height: 100%;
        border: 0.5px solid grey;
        border-radius: 4px;
        padding: 8px auto;
        margin-top: 6px;
  }

    input {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      // height: 40px;
      height: auto;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      border: 0.5px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #667085;
      outline: none;
      margin-top: 10px;

      ::placeholder {
        // padding-left: 10px;
      }

      :focus {
        width: 98%;
        border: 1px solid #45a049;
        padding-left: 10px;

        ::placeholder {
          padding-left: 0px;
        }
      }
    }
  }

  @media screen and (min-width: 1025px) {
    width: 100%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background: green;

    label {
      // background: yellow;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  
      width: inherit;
      height: 24px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #344054;
      margin-bottom: 30px;
  
      input {
        display: flex;
        flex-direction: row;
        align-items: center;
        // width: 280px;
        width: 100%;
        height: 40px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        margin-top: 6px;
        padding: 10px 0px;
        font-family: "Komet";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #667085;
        outline: none;
  
        ::placeholder {
          padding-left: 10px;
        }
  
        :focus {
          width: 96%;
          border: 1px solid #45a049;
          padding-left: 10px;
  
          ::placeholder {
            padding-left: 0px;
          }
        }
      }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Err = styled.div`
  font-size: smaller;
  color: red;
  width: 90%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0px;
`;
export const LoginButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 44px;
  margin-bottom: 16px;
  height: 48px;
  background: #08783d;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  transition: background 1s ease-in-out;
  //   margin-top: 100px;
  z-index: 999;

  :hover {
    cursor: pointer;
    background: linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );
    transition: transform;
    color: #08783d;
  }
`;
export const LoginLocked = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #ebf5f0;
  border-radius: 4px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 3px;
  }

  p {
    width: 172px;
    height: 18px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #08783d;
    margin-left: 3px;
  }
`;

// end of form style
